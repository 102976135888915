import React from 'react';
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import styled from 'styled-components';
import { Heading, Subtitle } from '@/styles/global-styled-components';
import { Button } from '@/components/Base/Button';
import { StaticImage } from 'gatsby-plugin-image';
import { POINTS_URL } from '@/utils/constants';
import 'animate.css';
import Point from '@/assets/images/point.svg';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  a {
    color: #0095eb;
  }

  p {
    margin: 50px 0;
  }

  ul {
    margin: 0 0 15px 30px;
    list-style: disc outside;
  }

  ol,
  li {
    margin-bottom: 10;
  }
`;

const ViewportHeight = styled.div`
  // min-height: 700px;
  height: 93vh;
  max-height: -webkit-fill-available; // for safari
  align-items: center;
  justify-content: center;
  @media ${(props) => `${props.theme.screens.landscape.raw}`} {
  }
`;

const Home = () => (
  <Layout>
    <SEO />
    <Container>
      <ViewportHeight className="page-padding flex flex-col align-middle justify-between relative items-center">
        <img src={Point} className='absolute top-[5%] sm:top-[15%] sm:right-[10%] right-[2%]' />
        <img src={Point} className='absolute top-[50%]  sm:top-[60%] sm:left-[10%] left-[2%]' />
        <div>
          <div className="landscape:w-full animate__animated animate__fadeIn">
            <Heading className="pb-6 text-center pt-32 ">
              Supporting a New Era of <br /> Crypto Volatility
            </Heading>
            <Button
              color="green"
              className="mx-auto mb-40"
              onClick={() => {
                window.open(POINTS_URL, '_blank');
              }}
            >
              Start Earning Points
            </Button>
            <Subtitle
              light
              shrink
              className="leading-7 max-w-[1500px] text-md mx-auto pb-6"
            >
              The Volmex Foundation's mission is to support open, free, and fair
              financial markets by making crypto volatility indices and
              volatility products more globally accessible.
            </Subtitle>
          </div>
        </div>
      </ViewportHeight>
    </Container>
  </Layout>
);

export default Home;
